import {  Container } from '@mui/material';
import Items from './Items';
import Rentals from './Rentals';

export default function Shop(props) {    

    return <Container sx={{mt:{xs:0,md:0},p:{xs:0}}}>
        <Items column={props?.column}/>
        <Rentals  column={props?.column}/>
    </Container>
}