import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignUp from '../user/SignUp';
import EmailVerify from '../user/EmailVerify';
import ResetPwd from '../user/ResetPwd';
import Event from '../event/Event';
import { LoginRoute } from './AuthRoute';
import './index.css'
import Layout from '../Component/Layout';
import Kids from '../member/Kids';
import Transactions from '../member/Transactions';
import Terms from '../user/Terms';
import Events from '../member/Events';
import HomeLayout from '../member/HomeLayout';
import MemberSchedule from "../member/Schedule"
import Lottery from '../event/Lottery';
import Competitions from '../member/Competitions';
import Recharge from '../member/Recharge';
import PayCallback from '../member/PayCallback';
import Shop from '../home/Shop';
const App = () => {
  const theme = createTheme();  
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>          
          <Route path="/" element={<Navigate to="/member/home"/>} />
          <Route path="/home" element={<Layout />} >
            {/* <Route path="index" element={<Home />} />
            <Route path="about" element={<Aboutus />} /> */}
            <Route path="shop" element={<Shop />} />
            {/* <Route path="schedule" element={<Schedule />} />
            <Route path="gallery" element={<Gallery />} />            
            <Route path="qa" element={<Qa />} /> */}
          </Route>
          {/* <Route path="/coach/:name" element={<Coach />} />  */}
          <Route path="/user" element={<Layout />}>
            <Route path="signup" element={<SignUp />} />
            <Route path="signin" element={<LoginRoute/>} />
            <Route path="resetpwd" element={<ResetPwd />} />
            <Route path="terms" element={<Terms />} />
            <Route path="emailverify" element={<EmailVerify />} />
          </Route>
          <Route path="/member" element={<HomeLayout />}>
            <Route path="events" element={<Events />} />
            <Route path="competitions" element={<Competitions />} />
            <Route path="home" element={<Transactions />}/>
            <Route path="kids" element={<Kids />}/>
            <Route path="shop" element={<Shop column={6}/>} />
            <Route path="transactions" element={<Transactions />}/>
            <Route path="schedule" element={<MemberSchedule />} />            
            <Route path="recharge" element={<Recharge />} />    
            <Route path="paymentcallback" element={<PayCallback />} />
          </Route>
          <Route path="/event/:code" element={<Event />} />
          <Route path="/lottery/:code" element={<Lottery />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
