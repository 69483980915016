import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Alert, Container, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import apis from '../api';
import { apiResult } from '../Utils/Common';

export default function Rentals(props) {
    const [items, setItems] = React.useState()
    const [error, setError] = React.useState()
    const hasLoaded = React.useRef(false)
    React.useEffect(() => {
        if (!hasLoaded.current) {
            hasLoaded.current = true
            apis.loadRentals().then(ret => {
                apiResult(ret, data => {
                    setItems(data)
                }, setError)
            })
        }
    }, [])

    return <Container sx={{m:0,p:0, mt:2}}>
        <Box sx={{ display: {xs:"",md:'flex'}, alignItems:'baseline', pl: 1.5, pb: 1 }}>
            <Typography variant="h4" component="div">Equipment Rental</Typography>
            <Typography variant="subtitle" component="div" sx={{ color: 'text.secondary',ml:{xs:0,md:2} }}>Please contact the administrator for rental.</Typography>
        </Box>
        {error && <Alert variant='error'>{error}</Alert> }
        <Grid container>
            {items && items.map((item, idx) => {
                return <Grid key={idx} xs={12} md={props?.column || 4}>
                    <Card sx={{ display: 'flex',m:1}}>
                        <CardMedia
                            component="img"
                            sx={{ width: {xs:120,md:151},height:{xs:122,md:151} }}
                            image={"/memberapi/home/photo?file="+item.photo}
                            alt="Item photo doesn't exists"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto',p:{md:"16px",xs:"10px"} }}>                               
                                <Typography component="div"  variant={"h6"}>{item.product}</Typography>                                
                                <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>In stock: {item.remained}</Typography>
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', pl: {md:2,xs:"10px"}, pb: {md:2,xs:"10px"} }}>
                                <Typography variant="subtitle2" component="div">${item.month_price}/month</Typography>
                                <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary',ml:{xs:1,md:2} }}>${item.daily_price}/day</Typography>
                            </Box>
                        </Box>

                    </Card>
                </Grid>
            })}

        </Grid>
    </Container>
}